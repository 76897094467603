import React from 'react'

import Swiper from 'react-id-swiper'

import IconeChat from 'images/icone-depoimentos.svg'

import ArrowSlide from 'images/arrow-slide-depo.svg'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

function SectionDepoimentos() {
  const images = useStaticQuery(graphql`
    query {
      FranciscoJunior: file(relativePath: { eq: "depoimentos/img-depo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ThiagoMacabu: file(relativePath: { eq: "depoimentos/thiago-macabu.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      LucySantos: file(relativePath: { eq: "depoimentos/lucy-santos.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 88) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      EneasSouza: file(relativePath: { eq: "depoimentos/eneas-souza.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Juliana: file(relativePath: { eq: "depoimentos/juliana-panicio.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ElmoReis: file(relativePath: { eq: "depoimentos/elmo-reis.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      NataliaPolo: file(relativePath: { eq: "depoimentos/natalia-polo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Alciece: file(relativePath: { eq: "depoimentos/alciene-antonio.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Evaldo: file(relativePath: { eq: "depoimentos/evaldo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 570, maxHeight: 427, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const params = {
    speed: 700,
    preloadImages: false,
    pagination: {
      el: '.s-depoimentos .dir .area-slide .ctrl-slide .swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '#btn-next-slide-depoimentos',
      prevEl: '#btn-prev-slide-depoimentos',
    },
    breakpoints: {
      1024: {
        autoHeight: true,
      },
    },
  }

  return (
    <section className="s-depoimentos">
      <div className="container">
        <img src={IconeChat} data-aos="fade-up" className="icone" alt="Icone chat" />
        <h2 data-aos="fade-up">Veja o que os clientes estão falando</h2>
        <p data-aos="fade-up">Quem está com a gente pode falar o quanto nossa solução facilita e agiliza o seu dia a dia.</p>
        <div className="dir">
          <div className="area-slide" data-aos="fade-left">
            <Swiper {...params}>
              <div>
                <div className="img">
                  <Img
                    fluid={images.FranciscoJunior.childImageSharp.fluid}
                    alt="Imagem de Francisco Júnior Taquarana na Drogaria Bela Vista | Taquarana - AL"
                  />
                </div>
                <div className="info-depo">
                  <div className="user">
                    <div className="info">
                      <h3>Francisco Júnior Taquarana</h3>
                      <span>Drogaria Bela Vista | Taquarana - AL</span>
                    </div>
                  </div>
                  <p className="text-depo">
                    Após a utilização do INOVAFARMA pude ter uma visão clara de toda gestão da farmácia. A facilidade de executar as
                    atividades é uma das características que me fez mudar para o INOVAFARMA. Estou muito satisfeito também com toda
                    assistência prestada pela empresa revenda Suporti, em especial a Iany que não só implantou o sistema como está de
                    prontidão para qualquer dúvida. <br />
                    Indicaria o sistema para qualquer interessado em abrir uma farmácia ou mudar de sistema, certeza que trará muitos
                    benefícios.{' '}
                  </p>
                </div>
              </div>
              <div>
                <div className="img">
                  <Img
                    fluid={images.ThiagoMacabu.childImageSharp.fluid}
                    alt="Imagem de Thiago Macabu na Drogaria Macabu | Casimiro de Abreu – RJ"
                  />
                </div>
                <div className="info-depo">
                  <div className="user">
                    <div className="info">
                      <h3>Thiago Macabu</h3>
                      <span>Drogaria Macabu | Casimiro de Abreu – RJ</span>
                    </div>
                  </div>
                  <p className="text-depo">
                    A gente implantou em meados de dezembro de 2018. A mudança não foi “doida” como a gente achou que iria ser. Enfim, eu
                    estou muito satisfeito com o sistema, mas muito mesmo. Esse sistema tem o que eu sempre sonhei em ter aqui na Drogaria
                    Macabu: um contas a pagar show de bola, sistema fácil, dinâmico de trabalhar na área de vendas, devolução, a entrega
                    você pode fazer de uma loja para outra, em compras o sistema tem parcerias com o Cotefácil que já está integrado.
                  </p>
                </div>
              </div>
              <div>
                <div className="img">
                  <Img
                    fluid={images.LucySantos.childImageSharp.fluid}
                    alt="Imagem de Lucy Santos na Farmácia do Trabalhador | Taquarana - AL"
                  />
                </div>
                <div className="info-depo">
                  <div className="user">
                    <div className="info">
                      <h3>Lucy Santos</h3>
                      <span>Farmácia do Trabalhador | Taquarana - AL</span>
                    </div>
                  </div>
                  <p className="text-depo">
                    O INOVAFARMA superou minhas expectativas, desde a implantação como no suporte. Profissionais maravilhosos que contribuem
                    para o crescimento da minha farmácia e foram permeando outros produtos que trouxeram resultados que nunca havia
                    atingido. Em síntese, só tenho a agradecer pelo auxílio que simplificou minha vida, me deixando segura pelo controle que
                    hoje tenho. Obrigada mil vezes!!!
                  </p>
                </div>
              </div>
              <div>
                <div className="img">
                  <Img fluid={images.EneasSouza.childImageSharp.fluid} alt="Imagem de Eneas Souza na Farmácias Farmatex | Curitiba - PR" />
                </div>
                <div className="info-depo">
                  <div className="user">
                    <div className="info">
                      <h3>Eneas Souza</h3>
                      <span>Farmácias Farmatex | Curitiba - PR</span>
                    </div>
                  </div>
                  <p className="text-depo">
                    As Farmácias Farmatex, desde o primeiro contato comercial até a implantação do sistema INOVAFARMA pela Precisão Sistemas
                    em nossas duas lojas, sempre teve um ótimo atendimento, contando com um suporte técnico ágil, imediato e com a
                    resolutiva das dificuldades da equipe solucionada remotamente em poucos minutos. O Sistema INOVAFARMA é prático em sua
                    utilização, com seus módulos, atendendendo todas as necessidades da loja, tanto comercial quanto gerencial.
                  </p>
                </div>
              </div>
              <div>
                <div className="img">
                  <Img fluid={images.Juliana.childImageSharp.fluid} alt="Imagem de Juliana Pânicio na MaxiFarma Cajuru | Curitiba - PR" />
                </div>
                <div className="info-depo">
                  <div className="user">
                    <div className="info">
                      <h3>Juliana Pânicio</h3>
                      <span>MaxiFarma Cajuru | Curitiba - PR</span>
                    </div>
                  </div>
                  <p className="text-depo">
                    Indico o sistema INOVAFARMA de olhos fechados, atendimento excelente, sempre que a minha equipe tem dúvidas ou problemas
                    eles estão em prontidão para nos atender em qualquer dia e hora, não existe fila de espera como nos outros softwares que
                    já utilizei. O sistema é prático e fácil de ser utilizado. As ferramentas são simples e fáceis de serem utilizadas.
                  </p>
                </div>
              </div>
              <div>
                <div className="img">
                  <Img
                    fluid={images.ElmoReis.childImageSharp.fluid}
                    alt="Imagem de Elmo dos Reis na MaxiFarma Campo Largo | Curitiba - PR"
                  />
                </div>
                <div className="info-depo">
                  <div className="user">
                    <div className="info">
                      <h3>Elmo dos Reis</h3>
                      <span>MaxiFarma Campo Largo | Curitiba - PR</span>
                    </div>
                  </div>
                  <p className="text-depo">
                    Desde que decidimos trocar de sistema na farmácia e aderimos o INOVAFARMA como nosso sistema de gestão, eliminamos boa
                    parte de nossos problemas pois o sistema é fácil de mexer, permite acesso a muitas ferramentas, tem um suporte de rápido
                    retorno e o atendimento é excelente
                  </p>
                </div>
              </div>
              <div>
                <div className="img">
                  <Img
                    fluid={images.NataliaPolo.childImageSharp.fluid}
                    alt="Imagem de Natália Polo na Rede Bem Drogarias | Auriflama - SP"
                  />
                </div>
                <div className="info-depo">
                  <div className="user">
                    <div className="info">
                      <h3>Natália Polo</h3>
                      <span>Rede Bem Drogarias | Auriflama - SP</span>
                    </div>
                  </div>
                  <p className="text-depo">
                    Depois que migrei para o sistema INOVAFARMA, tudo ficou muito mais prático, consigo verificar com muita facilidade os
                    relatórios, ter controle de tudo, administrar meu estoque e minhas vendas de forma clara e precisa!
                  </p>
                </div>
              </div>
              <div>
                <div className="img">
                  <Img
                    fluid={images.Alciece.childImageSharp.fluid}
                    alt="Imagem de Alciene Antonia Desiderio Cabrera na RedeBem | General Salgado - SP"
                  />
                </div>
                <div className="info-depo">
                  <div className="user">
                    <div className="info">
                      <h3>Alciene Antonia Desiderio Cabrera</h3>
                      <span>RedeBem | General Salgado - SP</span>
                    </div>
                  </div>
                  <p className="text-depo">
                    Com a migração para o INOVAFARMA a rotina de trabalho melhorou muito. Sistema fácil e leitura simples de relatórios. As
                    informações são fáceis para se obter e entender. Os funcionários sempre muito atenciosos. Exploro muito o sistema, e
                    qualquer dúvida que eu ou a equipe temos, ligo ou chamo no WhatsApp ou suporte remoto e consigo resolver na hora. Indico
                    e recomendo a todos para que façam adesão, pois a migração muito simples sem transtornos.
                  </p>
                </div>
              </div>
              <div>
                <div className="img">
                  <Img fluid={images.Evaldo.childImageSharp.fluid} alt="Imagem de Evaldo Fogaca na Rede Braslu | Curitiba - PR" />
                </div>
                <div className="info-depo">
                  <div className="user">
                    <div className="info">
                      <h3>Evaldo Fogaca</h3>
                      <span>Rede Braslu | Curitiba - PR</span>
                    </div>
                  </div>
                  <p className="text-depo">
                    Aqui na Rede de Farmácias Braslu, optamos pelo sistema INOVAFARMA por ser de fácil utilização, não ser pesado nas
                    máquinas e ter um suporte técnico sempre a nossa disposição para quaisquer dúvidas e esclarecimentos. Nós usamos e
                    recomendamos aos colegas de profissão e aos proprietários de farmácias usarem o INOVAFARMA. A Braslu faz parte da
                    Inovação com o INOVAFARMA!
                  </p>
                </div>
              </div>
            </Swiper>
            <div className="ctrl-slide">
              <div className="btns">
                <img src={ArrowSlide} className="btn btn-prev" id="btn-prev-slide-depoimentos" alt="Icone seta anterior" />
                <img src={ArrowSlide} className="btn btn-next" id="btn-next-slide-depoimentos" alt="Icone seta próximo" />
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionDepoimentos
