import React, { Component } from 'react'
import { Link } from 'gatsby'

import IconeInovaFarma from '../../images/icone-nf.svg'
import IlustraPotencial from '../../images/ilustra-potencial.svg'
import IconePotencial from '../../images/icone-potencial.svg'
import IlustraGarantia from '../../images/ilustra-garantia.svg'
import IlustraControle from '../../images/ilustra-controle.svg'
import IlustraAssegurar from '../../images/ilustra-assegurar.svg'
import IlustraLucratividade from '../../images/ilustra-lucratividade.svg'
import IconeLucratividade from '../../images/icone-lucratividade-farm.svg'
import IconeGarantia from '../../images/icone-garantia.svg'
import IconeControle from '../../images/icone-controle.svg'
import IconeAssegure from '../../images/icone-assegure.svg'

class SectionInfoGeral extends Component {
  render() {
    return (
      <section className="s-info-geral">
        <div className="container">
          <div className="icone">
            <img src={IconeInovaFarma} alt="Icone Inovafarma" />
          </div>
          <div className="item first">
            <img src={IlustraPotencial} data-aos="fade-right" className="ilustra" alt="Graficos perfil cliente e tela venda Inovafarma" />
            <div className="texto" data-aos="fade-left">
              <img src={IconePotencial} className="icone-texto" alt="Icone dinheiro" />
              <h2>Potencialize suas vendas com atendimento ágil</h2>
              <p>
                No INOVAFARMA você pode contar com os principais PBM´s do mercado farmacêutico, entre eles: Farmácia Popular, VidaLink e
                Transaction Centre, além das ferramentas de fidelização PEC FEBRAFAR e OBJECT PRO.
              </p>
              <p>
                Com isso você aumenta a agilidade e personaliza o seu atendimento no PDV, melhorando os resultados de fidelização da sua
                farmácia.
              </p>
              <Link to="/solucoes/" className="btn">
                Saiba como
              </Link>
            </div>
          </div>
          <div className="item second">
            <img
              src={IlustraGarantia}
              data-aos="fade-left"
              className="ilustra"
              alt="Bloco giro de estoque e lançamento de pedidos Inovafarma"
            />
            <div className="texto" data-aos="fade-right">
              <img src={IconeGarantia} className="icone-texto" alt="Icone garantia" />
              <h2>Garanta que seu estoque esteja sempre saudável</h2>
              <p>
                Ter um estoque assertivo garante que a sua farmácia conseguirá oferecer o melhor Mix de Produtos para seus clientes,
                evitando rupturas de vendas e garantindo melhores resultados no Ticket Médio.
              </p>
              <p>
                Com o processo de compras do INOVAFARMA você repõe seu estoque com clareza através das ferramentas de gestão como Estoque
                Regulador, Demanda da Curva ABC ou Estoque Mínimo e Máximo
              </p>
              <Link to="/solucoes/" className="btn">
                Saiba como
              </Link>
            </div>
          </div>
          <div className="item tree">
            <img
              src={IlustraControle}
              data-aos="fade-right"
              className="ilustra"
              alt="Tela sistema inovafarma de financeiro e contas a pagar com gráficos"
            />
            <div className="texto" data-aos="fade-left">
              <img src={IconeControle} className="icone-texto" alt="Icone de controle" />
              <h2>Controle as receitas e despesas da sua farmácia</h2>
              <p>
                No financeiro cada detalhe faz toda diferença, acompanhar as taxas administrativas de cartões, juros de movimentações e o
                prazo de recebimento do crediário, contribui para evitar as rupturas financeiras da sua farmácia.
              </p>
              <p>
                O INOVAFARMA ajuda no gerenciamento das Conciliações Bancárias e de Cartão, além de oferecer ferramentas gerenciais que
                auxiliam sua gestão financeira do dia a dia.
              </p>
              <Link to="/solucoes/" className="btn">
                Saiba como
              </Link>
            </div>
          </div>
          <div className="item second assegurar">
            <img
              src={IlustraAssegurar}
              data-aos="fade-left"
              className="ilustra"
              alt="Tela sistema Inovafarma de listas de notas para CNPJ da loja"
            />
            <div className="texto" data-aos="fade-right">
              <img src={IconeAssegure} className="icone-texto" alt="Icone assegurar" />
              <h2>Assegure que sua farmácia pague o imposto devido</h2>
              <p>
                As normas fiscais brasileiras são complexas e exigem atenção no cumprimento das obrigações vigentes, como na emissão de
                cupons e notas fiscais, SPED
              </p>
              <Link to="/solucoes/" className="btn">
                Saiba como
              </Link>
            </div>
          </div>
          <div className="item">
            <img
              src={IlustraLucratividade}
              data-aos="fade-right"
              className="ilustra"
              alt="Tela sistema Inovafarma DLP, DFC e DRE com gráficos"
            />
            <div className="texto" data-aos="fade-left">
              <img src={IconeLucratividade} className="icone-texto" alt="Icone lucratividade" />
              <h2>
                Qual a lucratividade da
                <br /> sua farmácia?
              </h2>
              <p>
                Quais são os grupos de produtos mais vendidos na farmácia? Qual a sua média de desconto? E o seu Ticket Médio? Seu CMV está
                equilibrado? O quanto as despesas consomem seu caixa?
              </p>
              <p>
                O INOVAFARMA te ajuda a acompanhar todos os KPI (indicadores de performance) do negócio, auxiliando na sua tomada de
                decisão.
              </p>
              <Link to="/solucoes/" className="btn">
                Saiba como
              </Link>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionInfoGeral
