import React, { Component } from 'react'
import { Link } from 'gatsby'

import ArrowDown from '../../images/arrow-down-blue.svg'
import IlustracaoMac from '../../images/imac-fullbanner.svg'
import IconeLucratividade from '../../images/icone-lucratividade.svg'
import IconeAtrair from '../../images/icone-atrair.svg'
import IconeMelhorar from '../../images/icone-melhorar.svg'
import IconeFamilia from '../../images/icone-familia.png'

class SectionPrioridade extends Component {
  clickSection = section => {
    const tabScroll = document.getElementById(section)
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: tabScroll.offsetTop,
    })
  }
  render() {
    return (
      <section className="s-prioridade">
        <div className="container">
          <div
            className="btn-next-section"
            role="link"
            onClick={e => {
              e.preventDefault()
              this.clickSection('prioridades')
            }}
          >
            <img src={ArrowDown} alt="Icone seta para baixo" />
          </div>
          <img src={IlustracaoMac} className="ilustracao-mac" alt="Tela sistema Inovafarma" />
          <div className="ajuda-resultados" id="prioridades">
            <h2>O que é prioridade hoje para você?</h2>
            <p>Podemos ajudar a melhorar seus resultados!</p>
            <div className="all">
              <div className="box">
                <img src={IconeLucratividade} alt="Icone aumentar lucratividade" />
                <span>
                  Aumentar a<br /> lucratividade da
                  <br /> farmácia
                </span>
              </div>
              <div className="box">
                <img src={IconeAtrair} alt="Icone atrair e fidelizar" />
                <span>
                  Atrair e fidelizar
                  <br /> seus clientes
                </span>
              </div>
              <div className="box">
                <img src={IconeMelhorar} alt="Icone melhorar organização" />
                <span>
                  Melhorar a<br /> organização
                  <br /> do seu negócio
                </span>
              </div>
              <div className="box">
                <img src={IconeFamilia} alt="Icone ter mais tempo para familia" />
                <span>
                  Ter mais tempo
                  <br /> para sua família
                </span>
              </div>
            </div>
            <Link to="/sobre/contato" className="btn" data-aos="fade-up">
              Quero utilizar na minha farmácia
            </Link>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionPrioridade
