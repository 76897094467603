import React, { Component } from 'react'
import AOS from 'aos'
import '../../Style/aos.css'

import IlustraFuncoes from '../../images/ilustra-funcoes.svg'
import IconeVenda from '../../images/icone-venda.svg'
import IconeCompra from '../../images/icone-compra.svg'
import IconeEstoque from '../../images/icone-estoque.svg'
import IconeFinanceiro from '../../images/icone-financeiro.svg'
import IconeGestao from '../../images/icone-gestao.svg'
import IconeFiscal from '../../images/icone-fiscal.svg'

class SectionVantagens extends Component {
  componentDidMount() {
    AOS.init({
      duration: 1000,
      once: true,
      disable: 'mobile',
    })
  }
  render() {
    return (
      <section className="s-vantagens">
        <div className="container">
          <div className="funcoes">
            <div className="esq" data-aos="fade-right">
              <h2>Completo, simples e eficiente</h2>
              <img src={IlustraFuncoes} className="mac" alt="Tela do sistema Inovafarma com icones" />
            </div>
            <ul data-aos="fade-left">
              <li>
                <div className="icone">
                  <img src={IconeVenda} alt="Icone venda" />
                </div>
                <div className="info">
                  <h3>Venda</h3>
                  <p>Melhore seu processo agilizando o atendimento.</p>
                </div>
              </li>
              <li>
                <div className="icone">
                  <img src={IconeCompra} alt="Icone compra" />
                </div>
                <div className="info">
                  <h3>Compra</h3>
                  <p>Compre certo através das ferramentas adequadas.</p>
                </div>
              </li>
              <li>
                <div className="icone">
                  <img src={IconeEstoque} alt="Icone estoque" />
                </div>
                <div className="info">
                  <h3>Estoque</h3>
                  <p>Controle seu estoque e não tenha mais problemas de rupturas.</p>
                </div>
              </li>
              <li>
                <div className="icone">
                  <img src={IconeFinanceiro} alt="Icone financeiro" />
                </div>
                <div className="info">
                  <h3>Financeiro</h3>
                  <p>Gerencie todas as entradas e saídas da sua farmácia.</p>
                </div>
              </li>
              <li>
                <div className="icone">
                  <img src={IconeGestao} alt="Icone gestao" />
                </div>
                <div className="info">
                  <h3>Gestão</h3>
                  <p>Acompanhe a performance do negócio com indicadores.</p>
                </div>
              </li>
              <li>
                <div className="icone">
                  <img src={IconeFiscal} alt="Icone fiscal" />
                </div>
                <div className="info">
                  <h3>Fiscal</h3>
                  <p>Esteja em dia com as obrigatoriedades fiscais.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionVantagens
