import React, { useState } from 'react'
import Swiper from 'react-id-swiper'

import { Link, useStaticQuery, graphql } from 'gatsby'

import ArrowNext from '../../images/arrow-next-slide.svg'

import BackgroundImage from 'gatsby-background-image'

const ManipulatingComponentOutSideSwiper = () => {
  const images = useStaticQuery(graphql`
    query {
      Slide01: file(relativePath: { eq: "banners/home/banner-slide-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Slide02: file(relativePath: { eq: "banners/home/banner-slide-02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Slide03: file(relativePath: { eq: "banners/home/banner-slide-03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 92) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [swiper, updateSwiper] = useState(null)
  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }
  const params = {
    speed: 700,
    preloadImages: false,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '.s-banner .ctrl-slide .swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  }
  return (
    <section className="s-banner">
      <Swiper {...params} getSwiper={updateSwiper}>
        <BackgroundImage Tag="div" className="slide01" fluid={images.Slide01.childImageSharp.fluid} backgroundColor={`transparent`}>
          <div className="container">
            <div className="texto">
              <span>Comece a inovar</span>
              <h2>Simples e inovador</h2>
              <p>O melhor sistema para farmácia do Brasil</p>
              <Link to="/sobre/contato" className="btn">
                Quero utilizar na minha farmácia
              </Link>
            </div>
          </div>
        </BackgroundImage>
        <BackgroundImage Tag="div" className="slide02" fluid={images.Slide02.childImageSharp.fluid} backgroundColor={`transparent`}>
          <div className="container">
            <div className="texto">
              <span>Comece a inovar</span>
              <h2>Atendimento personalizado</h2>
              <p>Equipe de profissionais que conhecem o seu negócio</p>
              <Link to="/sobre/contato" className="btn">
                Quero utilizar na minha farmácia
              </Link>
            </div>
          </div>
        </BackgroundImage>
        <BackgroundImage Tag="div" className="slide03" fluid={images.Slide03.childImageSharp.fluid} backgroundColor={`transparent`}>
          <div className="container">
            <div className="texto">
              <span>Comece a inovar</span>
              <h2>Sistema de gestão completa para sua farmácia!</h2>
              <Link to="/sobre/contato" className="btn">
                Quero utilizar na minha farmácia
              </Link>
            </div>
          </div>
        </BackgroundImage>
      </Swiper>
      <div className="ctrl-slide">
        <div className="container">
          <div className="btns">
            <button onClick={goPrev}>
              <img src={ArrowNext} className="btn btn-prev" alt="Icone seta anterior" />
            </button>
            <button onClick={goNext}>
              <img src={ArrowNext} className="btn btn-next" alt="Icone seta próximo" />
            </button>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
  )
}
export default ManipulatingComponentOutSideSwiper
