import React, { Component } from 'react'

import Slide from 'react-id-swiper'

import Video from 'react-player'

import { Link } from 'gatsby'

class SectionGestao extends Component {
  render() {
    const params = {
      speed: 700,
      spaceBetween: 20,
      preloadImages: false,
      pagination: {
        el: '.s-gestao .texto .swiper-pagination',
        type: 'progressbar',
        clickable: true,
      },
      navigation: {
        nextEl: '#arrow-next-slide-video',
        prevEl: '#arrow-prev-slide-video',
      },
    }
    return (
      <section className="s-gestao">
        <div className="container">
          <div className="texto">
            <h2>Rápido de aprender e fácil de usar</h2>
            <p>
              O modelo de implementação INOVAFARMA garante uma melhor experiência na instalação, configuração e treinamento da sua equipe
              com o sistema.
            </p>
            <Link to="/sobre/contato" className="btn">
              Solicite uma demonstração
            </Link>
          </div>
          <div className="area-slide">
            <Slide {...params}>
              <div>
                <Video className="video" url="https://www.youtube.com/watch?v=St1jaOCxPVw" light playing />
                <span>
                  Como você faz a <strong>Gestão Financeira</strong> da sua Farmácia?
                </span>
              </div>
            </Slide>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionGestao
