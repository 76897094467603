import React, { Component } from 'react'
import AOS from 'aos'
import '../Style/aos.css'

import Header from 'components/header'
import Banner from 'components/banner/main'
import Prioridade from 'components/s-prioridade/main'
import Vantagens from 'components/s-vantagens/main'
import InfoGeral from 'components/s-info-geral/main'
import Demo from 'components/s-demo/main'
import Depoimentos from 'components/depoimentos/main'
import Gestao from 'components/s-gestao/main'
import Footer from 'components/footer/main'
import SEO from 'components/seo'

import 'sass/main.scss'

class Site extends Component {
  componentDidMount() {
    AOS.init({
      duration: 1000,
      once: true,
      disable: 'mobile',
    })
  }
  render() {
    return (
      <div className="main">
        <SEO title="home" />
        <Header />
        <Banner />
        <Prioridade />
        <Vantagens />
        <InfoGeral />
        <Demo />
        <Depoimentos />
        <Gestao />
        <Footer padding_maior={false} />
      </div>
    )
  }
}

export default Site
